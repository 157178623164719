/**
 * Define some frequent-use constants
 * for easier import inside components
 *
 * @author name <name@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

// Languages
export const languages = {
	en: 'en',
	fi: 'fi'
}

// Path to images directory
export const IMAGE_PATH = `${process.cwd()}src/assets/images`
// Base url for files
/* export const BUCKET_URL =
	'https://humitori.fra1.digitaloceanspaces.com/development/' */
/* export const BUCKET_URL = 'https://humitoriteststorageacc.blob.core.windows.net/publicfiles/' */
export const BUCKET_URL = process.env.BUCKET_URL
// Path to icons directory
export const ICON_PATH = `${process.cwd()}src/assets/icons`
// Key redux persist
export const PERSIST_KEY = 'root'
//
export const BASE_URL = process.env.BACKEND_BASE_URL || 'http://localhost:3000/'
export const REPORTING_BASE_URL =
	process.env.REPORTING_BASE_URL || 'http://localhost:3002/'
//'https://humitori.appspot.com/'
//'https://api.humitor.vertics.co/'
//Navigation
export const routerPath = {
	home: '/',
	editCompany: '/edit-company',
	editPerson: '/edit-person',
	login: '/kirjautuminen',
	logout: '/kirjaudu-ulos',
	job: '/luo-uusi',
	editJob: '/muokka-kohde/:id',
	workInAddress: '/kohde',
	apartment: '/tila',
	workLog: '/tyot',
	controlPanel: '/hallinta',
	userPermission: '/vastuuhenkilot',
	inviteUser: '/kutsukayttaja',
	deleteUser: '/poistakayttaja',
	customerRegister: '/asiakasrekisteri',
	workHours: '/tyotunnit',
	productManagement: '/tuotteet',
	electricityPaymentManagement: '/electricityPayments',
	pictures: '/kuvat',
	documents: '/dokumentit',
	reports: '/raportit/:id',
	createReport: '/raportinluonti/:id',
	previewMeasurementReport: '/loppuselvityspöytakirja/:id',
	previewNewJobSpecificationReport: '/tyoselvitysraportti/:id',
	uploadReport: '/lataaraportti/:id',
	electricityRefundForm: '/sahkohyvitys',
	editingAttribute: '/muokkaa-tyotyyppeja',
	machines: `/machines`,
	roles: `/roles`,
	materials: `/materials`,
	parameters: `/parameters`,
	tooltips: `/tooltips`
}

export const measurement = {
	value: {
		name: 'Mitattumärkäärvo',
		type: 'text',
		shortName: 'Mitattu arvo'
	},
	dryComparedValue: {
		name: 'Kuiva-arvo',
		type: 'text',
		shortName: 'Kuiva arvo'
	},
	sampleMeasurement: {
		name: 'Koepalamittaus',
		type: 'checkbox',
		shortName: 'Koepalamittaus'
	},
	drillMeasurement: {
		name: 'Porareikämittaus',
		type: 'checkbox',
		shortName: 'Porareikämittaus'
	},
	abs: {
		name: `Abs.\u200Bkosteus (G/m3) -\u2060arvo`,
		type: 'text',
		shortName: 'Abs. (G/m\u00B3)'
	},
	temperature: {
		name: 'Lämpötila (C) -\u2060arvo',
		type: 'text',
		shortName: 'Lämpö (C)'
	},
	depth: {
		name: 'Syvyys (cm) -\u2060arvo',
		type: 'text',
		shortName: 'Syvyys (cm)'
	},
	rh: { name: 'RH (%) -\u2060arvo', type: 'text', shortName: 'RH (%)' },
	mp: { name: 'MP nro', type: 'text', shortName: 'MP nro' }
}
