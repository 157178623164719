import { ParkingFee } from '../models/parkingFee'
import { GetAction } from './action'
import { parkingFeeTypes as types } from './types'

const entityName = 'parking_fee'

export const get = (): GetAction<types> => {
	return {
		type: types.getAll,
		payload: {
			request: {
				method: 'GET',
				url: `/default/${entityName}`
			}
		}
	}
}

export const getByWorkEventId = (workEventId: string): GetAction<types> => {
	return {
		type: types.getAll,
		payload: {
			request: {
				method: 'GET',
				url: `/default/${entityName}?filterParamName1=workEventId&filterParamValue1=${workEventId}`
			}
		}
	}
}

export const update = (updateData: Partial<ParkingFee>, id: number) => {
	return {
		type: types.update,
		payload: {
			request: {
				method: 'PATCH',
				url: `/default/${entityName}/${id}`,
				data: updateData
			}
		}
	}
}

export const create = (data: Partial<ParkingFee>) => {
	return {
		type: types.create,
		payload: {
			request: {
				method: 'POST',
				url: `/default/${entityName}`,
				data: data
			}
		}
	}
}
