/**
 * Declare all types and export
 *
 * @author name <name@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

// Login
export const loginTypes = {
	changeField: 'LOGIN_CHANGE_FIELD',
	login: 'LOGIN',
	loginSuccess: 'LOGIN_SUCCESS',
	loginFail: 'LOGIN_FAIL',
	sendSMS: 'SEND_SMS',
	sendSMSSuccess: 'SEND_SMS_SUCCESS',
	sendSMSFail: 'SEND_SMS_FAIL',
	logout: 'LOG_OUT',
	get: 'GET_CURRENT_USER',
	getSuccess: 'GET_CURRENT_USER_SUCCESS',
	getFail: 'GET_CURRENT_USER_FAIL'
}
export const loadingTypes = {
	setLoading: 'SET_LOADING',
	stopLoading: 'STOP_LOADING'
}
// Registration
export const REGISTER = 'REGISTER'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_FAIL = 'REGISTER_FAIL'

// Jobs
export const jobTypes = {
	changeField: 'JOB_CHANGE_FIELD',
	changeFieldMaintenanceContact: 'JOB_MAINTENANCE_CHANGE_FIEL',
	changeFieldLandLordContact: 'JOB_LANDLORD_CHANGE_FIELD',
	changeFieldLandLordContactsArray: 'JOB_LANDLORDS_ARRAY_CHANGE_FIELD',
	// LANDLORD
	landLordAdd: 'JOB_ADD_LANDLORD',
	landLordDelete: 'JOB_DELETE_LANDLORD',
	// ROLES
	roleAdd: 'JOB_ADD_ROLE',
	roleDelete: 'JOB_DELETE_ROLE',
	// CREATE
	create: 'JOB_CREATE',
	createSuccess: 'JOB_CREATE_SUCCESS',
	createFail: 'JOB_CREATE_FAIL',
	// GET
	getAllJobs: 'JOB_GET_JOBS',
	getAllJobsSuccess: 'JOB_GET_JOBS_SUCCESS',
	getAllJobsFail: 'JOB_GET_JOBS_FAIL',
	getJobById: 'JOB_GET_JOB',
	getJobByIdSuccess: 'JOB_GET_JOB_SUCCESS',
	getJobByIdFail: 'JOB_GET_JOB_FAIL',
	// DELETE
	delete: 'JOB_DELETE',
	deleteSuccess: 'JOB_DELETE_SUCCESS',
	deleteFail: 'JOB_DELETE_FAIL',
	// initialize
	initializeNewJobStates: 'JOB_INITIAL_NEWJOB_STATES'
}

//WorkLogTypes
export const workLogTypes = {
	changeField: 'WORKLOG_CHANGE_FIELD',
	// CREATE
	create: 'WORKLOG_CREATE',
	createSuccess: 'WORKLOG_CREATE_SUCCESS',
	createFail: 'WORKLOG_CREATE_FAIL',
	// initialize
	resetState: 'WORKLOG_INITIAL_STATES'
}

export const errorTypes = {
	clear: 'ERROR_CLEAR_MESSAGE',
	showError: 'SHOW_ERROR'
}
export const notificationTypes = {
	clearNotification: 'CLEAR_NOTIFICATION',
	showNotification: 'NOTIFICATION_SHOW_NOTIFICATION'
}

/**
 *
 *
 *
 *
 * Modified types begin
 **/

//Apartments
export const apartmentTypes = {
	reset: 'APARTMENTS_RESET',

	electricityPaymentDone: 'APARTMENT_ELECTRICITY_PAYMENT_DONE',
	electricityPaymentDoneSuccess: 'APARTMENT_ELECTRICITY_PAYMENT_DONE_SUCCESS',
	electricityPaymentDoneFail: 'APARTMENT_ELECTRICITY_PAYMENT_DONE_FAIL',

	changeFieldApartment: 'APARTMENT_CHANGE_FIELD',
	changeFieldApartments: 'APARTMENTS_CHANGE_FIELD',
	changeFieldApartmentsArray: 'APARTMENTS_ARRAY_CHANGE_FIELD',
	// CREATE
	add: 'APARTMENTS_ADD',
	addSuccess: 'APARTMENTS_ADD_SUCCESS',
	addFail: 'APARTMENTS_ADD_FAIL',
	// GET
	getApartments: 'GET_APARTMENTS',
	getApartmentsSuccess: 'GET_APARTMENTS_SUCCESS',
	getApartmentsFail: 'GET_APARTMENTS_FAIL',
	// SET
	set: 'APARTMENT_SET',
	unset: 'APARTMENT_UNSET',
	setDeleteId: 'APARTMENT_SET_DELETE',
	//DELETE
	delete: 'APARTMENT_DELETE',
	deleteSuccess: 'APARTMENT_DELETE_SUCCESS',
	deleteFail: 'APARTMENT_DELETE_FAIL',
	// ADD TO SERVER
	create: 'APARTMENT_POST',
	createSuccess: 'APARTMENT_POST_SUCCESS',
	createFail: 'APARTMENT_POST_FAIL',
	// UPDATE IN SERVER
	update: 'APARTMENT_UPDATE',
	updateSuccess: 'APARTMENT_UPDATE_SUCCESS',
	updateFail: 'APARTMENT_UPDATE_FAIL',
	// UPDATE RELATION IN SERVER
	updateRelation: 'APARTMENT_UPDATE_RELATION',
	updateRelationSuccess: 'APARTMENT_UPDATE_RELATION_SUCCESS',
	updateRelationFail: 'APARTMENT_UPDATE_RELATION_FAIL',
	// DELETE RELATION IN SERVER
	deleteRelation: 'APARTMENT_DELETE_RELATION',
	deleteRelationSuccess: 'APARTMENT_DELETE_RELATION_SUCCESS',
	deleteRelationFail: 'APARTMENT_DELETE_RELATION_FAIL',
	// DELETE FROM SERVER
	remove: 'APARTMENT_REMOVE',
	removeSuccess: 'APARTMENT_REMOVE_SUCCESS',
	removeFail: 'APARTMENT_REMOVE_FAIL',
	// GET APARTMENT
	get: 'APARTMENT_GET',
	getSuccess: 'APARTMENT_GET_SUCCESS',
	getFail: 'APARTMENT_GET_FAIL',
	// UPDATE APARTMENT
	updatePreviewApartment: 'UPDATE_PREVIEW_APARTMENT',
	updatePreviewApartmentSuccess: 'UPDATE_PREVIEW_APARTMENT_SUCCESS',
	updatePreviewApartmentFail: 'UPDATE_PREVIEW_APARTMENT_FAIL',
	// GET WORKLOGS
	getWorkLogs: 'WORKLOGS_GET',
	getWorkLogsSuccess: 'WORKLOGS_GET_SUCCESS',
	getWorkLogsFail: 'WORKLOGS_GET_FAIL',
	// TO INITIALIZE WORKLOGS
	resetWorkLog: 'RESET_WORKLOG',
	// Rooms
	addRoom: 'ADD_ROOM',
	// GET ALL APARTMENTS
	getAll: 'APARTMENT_GET_ALL',
	getAllSuccess: 'APARTMENT_GET_ALL_SUCCESS',
	getAllFail: 'APARTMENT_GET_ALL_FAIL',
	// GET BY ID APARTMENTS
	getById: 'APARTMENT_GET_BY_ID',
	getByIdSuccess: 'APARTMENT_GET_BY_ID_SUCCESS',
	getByIdFail: 'APARTMENT_GET_BY_ID_FAIL',
	// GET ELECTRICITY PAYMENTS
	getElectricPayments: 'APARTMENT_ELECTRICITY_PAYMENT',
	getElectricPaymentsSuccess: 'APARTMENT_ELECTRICITY_PAYMENT_SUCCESS',
	getElectricPaymentsFail: 'APARTMENT_ELECTRICITY_PAYMENT_FAIL',
	// GET WORKEVENT BY APARTMENT ID
	getWorkEventByApartmentId: 'APARTMENT_GET_WORK_EVENT_BY_APARTMENT_ID',
	getWorkEventByApartmentIdSuccess:
		'APARTMENT_GET_WORK_EVENT_BY_APARTMENT_ID_SUCCESS',
	getWorkEventByApartmentIdFail:
		'APARTMENT_GET_WORK_EVENT_BY_APARTMENT_ID_FAIL',
	// GET DETAIL BY ID APARTMENTS
	getDetailById: 'APARTMENT_GET_DETAIL_BY_ID',
	getDetailByIdSuccess: 'APARTMENT_GET_DETAIL_BY_ID_SUCCESS',
	getDetailByIdFail: 'APARTMENT_GET_DETAIL_BY_ID_FAIL',

	//GET check bank account token
	checkToken: 'APARTMENT_CHECK_BANK_ACCOUNT_TOKEN',
	checkTokenFail: 'APARTMENT_CHECK_BANK_ACCOUNT_TOKEN_FAIL',
	checkTokenSuccess: 'APARTMENT_CHECK_BANK_ACCOUNT_TOKEN_SUCCESS',

	//PATCH bank account data
	sendBankAccount: 'APARTMENT_SEND_BANK_ACCOUNT_TOKEN',
	sendBankAccountFail: 'APARTMENT_SEND_BANK_ACCOUNT_TOKEN_FAIL',
	sendBankAccountSuccess: 'APARTMENT_SEND_BANK_ACCOUNT_TOKEN_SUCCESS',

	//POST Electricity form
	sendElectricityForm: 'APARTMENT_SEND_ELECTRICITY_FORM',
	sendElectricityFormFail: 'APARTMENT_SEND_ELECTRICITY_FORM_FAIL',
	sendElectricityFormSuccess: 'APARTMENT_SEND_ELECTRICITY_FORM_SUCCESS'
}

export const cityChargeTypes = {
	//Get All
	get: 'CITYCHARGE_GET',
	getSuccess: 'CITYCHARGE_GET_SUCCESS',
	getFail: 'CITYCHARGE_GET_FAIL',
	//Post
	create: 'CITYCHARGE_CREATE',
	createSuccess: 'CITYCHARGE_CREATE_SUCCESS',
	createFail: 'CITYCHARGE_CREATE_FAIL',
	//Get by id
	getById: 'CITYCHARGE_GET_BY_ID',
	getByIdSuccess: 'CITYCHARGE_GET_BY_ID_SUCCESS',
	getByIdFail: 'CITYCHARGE_GET_BY_ID_FAIL',
	//Update
	update: 'CITYCHARGE_UPDATE',
	updateSuccess: 'CITYCHARGE_UPDATE_SUCCESS',
	updateFail: 'CITYCHARGE_UPDATE_FAIL',
	//Delete
	delete: 'CITYCHARGE_DELETE',
	deleteSuccess: 'CITYCHARGE_DELETE_SUCCESS',
	deleteFail: 'CITYCHARGE_DELETE_FAIL',
	//Update relations
	updateRelation: 'CITYCHARGE_UPDATE_RELATION',
	updateRelationSuccess: 'CITYCHARGE_UPDATE_RELATION_SUCCESS',
	updateRelationFail: 'CITYCHARGE_UPDATE_RELATION_FAIL',
	//Delete relation
	deleteRelation: 'CITYCHARGE_DELETE_RELATION',
	deleteRelationSuccess: 'CITYCHARGE_DELETE_RELATION_SUCCESS',
	deleteRelationFail: 'CITYCHARGE_DELETE_RELATION_FAIL'
}

export const commentTypes = {
	//Get All
	get: 'COMMENT_GET',
	getSuccess: 'COMMENT_GET_SUCCESS',
	getFail: 'COMMENT_GET_FAIL',
	//Post
	create: 'COMMENT_CREATE',
	createSuccess: 'COMMENT_CREATE_SUCCESS',
	createFail: 'COMMENT_CREATE_FAIL',
	//Get by id
	getById: 'COMMENT_GET_BY_ID',
	getByIdSuccess: 'COMMENT_GET_BY_ID_SUCCESS',
	getByIdFail: 'COMMENT_GET_BY_ID_FAIL',
	//Update
	update: 'COMMENT_UPDATE',
	updateSuccess: 'COMMENT_UPDATE_SUCCESS',
	updateFail: 'COMMENT_UPDATE_FAIL',
	//Delete
	delete: 'COMMENT_DELETE',
	deleteSuccess: 'COMMENT_DELETE_SUCCESS',
	deleteFail: 'COMMENT_DELETE_FAIL',
	//Update relations
	updateRelation: 'COMMENT_UPDATE_RELATION',
	updateRelationSuccess: 'COMMENT_UPDATE_RELATION_SUCCESS',
	updateRelationFail: 'COMMENT_UPDATE_RELATION_FAIL',
	//Delete relation
	deleteRelation: 'COMMENT_DELETE_RELATION',
	deleteRelationSuccess: 'COMMENT_DELETE_RELATION_SUCCESS',
	deleteRelationFail: 'COMMENT_DELETE_RELATION_FAIL'
}

export const companyTypes = {
	changeField: 'COMAPNY_CHANGE_FIELD',
	//Get All
	get: 'COMPANY_GET',
	getSuccess: 'COMPANY_GET_SUCCESS',
	getFail: 'COMPANY_GET_FAIL',
	//Post
	create: 'COMPANY_CREATE',
	createSuccess: 'COMPANY_CREATE_SUCCESS',
	createFail: 'COMPANY_CREATE_FAIL',
	//Get by id
	getById: 'COMPANY_GET_BY_ID',
	getByIdSuccess: 'COMPANY_GET_BY_ID_SUCCESS',
	getByIdFail: 'COMPANY_GET_BY_ID_FAIL',
	//Update
	update: 'COMPANY_UPDATE',
	updateSuccess: 'COMPANY_UPDATE_SUCCESS',
	updateFail: 'COMPANY_UPDATE_FAIL',
	//Delete
	delete: 'COMPANY_DELETE',
	deleteSuccess: 'COMPANY_DELETE_SUCCESS',
	deleteFail: 'COMPANY_DELETE_FAIL',
	//Update relations
	updateRelation: 'COMPANY_UPDATE_RELATION',
	updateRelationSuccess: 'COMPANY_UPDATE_RELATION_SUCCESS',
	updateRelationFail: 'COMPANY_UPDATE_RELATION_FAIL',
	//Delete relation
	deleteRelation: 'COMPANY_DELETE_RELATION',
	deleteRelationSuccess: 'COMPANY_DELETE_RELATION_SUCCESS',
	deleteRelationFail: 'COMPANY_DELETE_RELATION_FAIL'
}

// Documents, Files
export const fileTypes = {
	//Get All
	get: 'FILE_GET',
	getSuccess: 'FILE_GET_SUCCESS',
	getFail: 'FILE_GET_FAIL',
	//Update
	update: 'FILE_UPDATE',
	updateSuccess: 'FILE_UPDATE_SUCCESS',
	updateFail: 'FILE_UPDATE_FAIL',
	//Create link
	createLink: 'FILE_CREATE_LINK_REPORT',
	createLinkSuccess: 'FILE_CREATE_LINK_SUCCESS',
	createLinkFail: 'FILE_CREATE_CREATE_LINK_FAIL',
	//Post
	createMeasurementReport: 'FILE_CREATE_MEASUREMENT_REPORT',
	createMeasurementReportSuccess: 'FILE_CREATE_MEASUREMENT_REPORT_SUCCESS',
	createMeasurementReportFail: 'FILE_CREATE_MEASUREMENT_REPORT_FAIL',
	//Post
	createJobSpecificationReport: 'FILE_CREATE_JOB_SPECIFICATION_REPORT',
	createJobSpecificationReportSuccess:
		'FILE_CREATE_JOB_SPECIFICATION_REPORT_SUCCESS',
	createJobSpecificationReportFail: 'FILE_CREATE_JOB_SPECIFICATION_REPORT_FAIL',

	//Post Report
	postReport: 'FILE_POST_REPORT',
	postReportSuccess: 'FILE_POST_REPORT_SUCCESS',
	postReportFail: 'FILE_POST_REPORT_FAIL',
	//Delete
	delete: 'FILE_DELETE',
	deleteSuccess: 'FILE_DELETE_SUCCESS',
	deleteFail: 'FILE_DELETE_FAIL',
	//Update relations
	updateRelation: 'FILE_UPDATE_RELATION',
	updateRelationSuccess: 'FILE_UPDATE_RELATION_SUCCESS',
	updateRelationFail: 'FILE_UPDATE_RELATION_FAIL',
	//Delete relation
	deleteRelation: 'FILE_DELETE_RELATION',
	deleteRelationSuccess: 'FILE_DELETE_RELATION_SUCCESS',
	deleteRelationFail: 'FILE_DELETE_RELATION_FAIL'
}

export const materialTypes = {
	//GET material type
	get: 'GET_MATERIAL_TYPE',
	getFail: 'GET_MATERIAL_TYPE_FAIL',
	getSuccess: 'GET_MATERIAL_TYPE_SUCCESS',
	//Create material type
	create: 'CREATE_MATERIAL_TYPE',
	createFail: 'CREATE_MATERIAL_TYPE_FAIL',
	createSuccess: 'CREATE_MATERIAL_TYPE_SUCCESS',
	// Update material type
	update: 'UPDATE_MATERIAL_TYPE',
	updateFail: 'UPDATE_MATERIAL_TYPE_FAIL',
	updateSuccess: 'UPDATE_MATERIAL_TYPE_SUCCESS'
}

export const jobTypeTypes = {
	changeField: 'JOBTYPE_CHANGE_FIELD',
	changeFieldValueOfAttributes: 'JOBTYPE_VALUE_OF_ATTRIBUTES_CHANGE_FIELD',
	// initialize
	resetState: 'JOBTYPE_INITIAL_STATES',
	//Get All
	get: 'JOBTYPE_GET',
	getSuccess: 'JOBTYPE_GET_SUCCESS',
	getFail: 'JOBTYPE_GET_FAIL',
	//Post
	create: 'JOBTYPE_CREATE',
	createSuccess: 'JOBTYPE_CREATE_SUCCESS',
	createFail: 'JOBTYPE_CREATE_FAIL',
	//Get by id
	getById: 'JOBTYPE_GET_BY_ID',
	getByIdSuccess: 'JOBTYPE_GET_BY_ID_SUCCESS',
	getByIdFail: 'JOBTYPE_GET_BY_ID_FAIL',
	//Update
	update: 'JOBTYPE_UPDATE',
	updateSuccess: 'JOBTYPE_UPDATE_SUCCESS',
	updateFail: 'JOBTYPE_UPDATE_FAIL',
	//Delete
	delete: 'JOBTYPE_DELETE',
	deleteSuccess: 'JOBTYPE_DELETE_SUCCESS',
	deleteFail: 'JOBTYPE_DELETE_FAIL',
	//Update relations
	updateRelation: 'JOBTYPE_UPDATE_RELATION',
	updateRelationSuccess: 'JOBTYPE_UPDATE_RELATION_SUCCESS',
	updateRelationFail: 'JOBTYPE_UPDATE_RELATION_FAIL',
	//Delete relation
	deleteRelation: 'JOBTYPE_DELETE_RELATION',
	deleteRelationSuccess: 'JOBTYPE_DELETE_RELATION_SUCCESS',
	deleteRelationFail: 'JOBTYPE_DELETE_RELATION_FAIL',
	//POST material
	createMaterial: 'JOBTYPE_POST_MATERIAL',
	createMaterialFail: 'JOBTYPE_POST_MATERIAL_FAIL',
	createMaterialSuccess: 'JOBTYPE_POST_MATERIAL_SUCCESS',

	//Update relation material
	updateRelationMaterial: 'JOBTYPE_UPDATE_RELATION_MATERIAL',
	updateRelationMaterialFail: 'JOBTYPE_UPDATE_RELATION_MATERIAL_FAIL',
	updateRelationMaterialSuccess: 'JOBTYPE_UPDATE_RELATION_MATERIAL_SUCCESS',

	//GET machine_type
	getMachineType: 'JOBTYPE_GET_MACHINE_TYPE',
	getMachineTypeFail: 'JOBTYPE_GET_MACHINE_TYPE_FAIL',
	getMachineTypeSuccess: 'JOBTYPE_GET_MACHINE_TYPE_SUCCESS',

	//POST install machine
	installMachine: 'JOBTYPE_INSTALL_MACHINE',
	installMachineFail: 'JOBTYPE_INSTALL_MACHINE_FAIL',
	installMachineSuccess: 'JOBTYPE_INSTALL_MACHINE_SUCCESS',
	deleteMachine: 'JOBTYPE_DELETE_MACHINE',
	deleteMachineSuccess: 'JOBTYPE_DELETE_MACHINE_SUCCESS',

	//PATCH uninstall machine
	updateMachine: 'JOBTYPE_UNINSTALL_MACHINE',
	updateMachineFail: 'JOBTYPE_UNINSTALL_MACHINE_FAIL',
	updateMachineSuccess: 'JOBTYPE_UNINSTALL_MACHINE_SUCCESS',

	//Create measurement
	createMeasurement: 'JOBTYPE_CREATE_MEASUREMENT',
	createMeasurementSuccess: 'JOBTYPE_CREATE_MEASUREMENT_SUCCESS',
	createMeasurementFail: 'JOBTYPE_CREATE_MEASUREMENT_FAIL',

	//Update relation Measurement
	updateRelationMeasurement: 'JOBTYPE_UPDATE_RELATION_MEASUREMENT',
	updateRelationMeasurementFail: 'JOBTYPE_UPDATE_RELATION_MEASUREMENT_FAIL',
	updateRelationMeasurementSuccess:
		'JOBTYPE_UPDATE_RELATION_MEASUREMENT_SUCCESS',

	//Create surface
	createSurface: 'JOBTYPE_CREATE_SURFACE',
	createSurfaceSuccess: 'JOBTYPE_CREATE_SURFACE_SUCCESS',
	createSurfaceFail: 'JOBTYPE_CREATE_SURFACE_FAIL',

	//Update relation Surface
	updateSurface: 'JOBTYPE_UPDATE_SURFACE',
	updateSurfaceFail: 'JOBTYPE_UPDATE_SURFACE_FAIL',
	updateSurfaceSuccess: 'JOBTYPE_UPDATE_SURFACE_SUCCESS'
}

export const machineInstallmentTypes = {
	//Get All
	get: 'MACHINEINSTALLMENT_GET',
	getSuccess: 'MACHINEINSTALLMENT_GET_SUCCESS',
	getFail: 'MACHINEINSTALLMENT_GET_FAIL',
	//Post
	create: 'MACHINEINSTALLMENT_CREATE',
	createSuccess: 'MACHINEINSTALLMENT_CREATE_SUCCESS',
	createFail: 'MACHINEINSTALLMENT_CREATE_FAIL',
	//Get by id
	getById: 'MACHINEINSTALLMENT_GET_BY_ID',
	getByIdSuccess: 'MACHINEINSTALLMENT_GET_BY_ID_SUCCESS',
	getByIdFail: 'MACHINEINSTALLMENT_GET_BY_ID_FAIL',
	//Update
	update: 'MACHINEINSTALLMENT_UPDATE',
	updateSuccess: 'MACHINEINSTALLMENT_UPDATE_SUCCESS',
	updateFail: 'MACHINEINSTALLMENT_UPDATE_FAIL',
	//Delete
	delete: 'MACHINEINSTALLMENT_DELETE',
	deleteSuccess: 'MACHINEINSTALLMENT_DELETE_SUCCESS',
	deleteFail: 'MACHINEINSTALLMENT_DELETE_FAIL',
	//Update relations
	updateRelation: 'MACHINEINSTALLMENT_UPDATE_RELATION',
	updateRelationSuccess: 'MACHINEINSTALLMENT_UPDATE_RELATION_SUCCESS',
	updateRelationFail: 'MACHINEINSTALLMENT_UPDATE_RELATION_FAIL',
	//Delete relation
	deleteRelation: 'MACHINEINSTALLMENT_DELETE_RELATION',
	deleteRelationSuccess: 'MACHINEINSTALLMENT_DELETE_RELATION_SUCCESS',
	deleteRelationFail: 'MACHINEINSTALLMENT_DELETE_RELATION_FAIL'
}

export const machineTypeTypes = {
	//Get All
	get: 'MACHINETYPE_GET',
	getSuccess: 'MACHINETYPE_GET_SUCCESS',
	getFail: 'MACHINETYPE_GET_FAIL',
	//Post
	create: 'MACHINETYPE_CREATE',
	createSuccess: 'MACHINETYPE_CREATE_SUCCESS',
	createFail: 'MACHINETYPE_CREATE_FAIL',
	//Get by id
	getById: 'MACHINETYPE_GET_BY_ID',
	getByIdSuccess: 'MACHINETYPE_GET_BY_ID_SUCCESS',
	getByIdFail: 'MACHINETYPE_GET_BY_ID_FAIL',
	//Update
	update: 'MACHINETYPE_UPDATE',
	updateSuccess: 'MACHINETYPE_UPDATE_SUCCESS',
	updateFail: 'MACHINETYPE_UPDATE_FAIL',
	//Delete
	delete: 'MACHINETYPE_DELETE',
	deleteSuccess: 'MACHINETYPE_DELETE_SUCCESS',
	deleteFail: 'MACHINETYPE_DELETE_FAIL',
	//Update relations
	updateRelation: 'MACHINETYPE_UPDATE_RELATION',
	updateRelationSuccess: 'MACHINETYPE_UPDATE_RELATION_SUCCESS',
	updateRelationFail: 'MACHINETYPE_UPDATE_RELATION_FAIL',
	//Delete relation
	deleteRelation: 'MACHINETYPE_DELETE_RELATION',
	deleteRelationSuccess: 'MACHINETYPE_DELETE_RELATION_SUCCESS',
	deleteRelationFail: 'MACHINETYPE_DELETE_RELATION_FAIL'
}

export const measurementTypes = {
	//Get All
	get: 'MEASUREMENT_GET',
	getSuccess: 'MEASUREMENT_GET_SUCCESS',
	getFail: 'MEASUREMENT_GET_FAIL',
	//Post
	create: 'MEASUREMENT_CREATE',
	createSuccess: 'MEASUREMENT_CREATE_SUCCESS',
	createFail: 'MEASUREMENT_CREATE_FAIL',
	//Get by id
	getById: 'MEASUREMENT_GET_BY_ID',
	getByIdSuccess: 'MEASUREMENT_GET_BY_ID_SUCCESS',
	getByIdFail: 'MEASUREMENT_GET_BY_ID_FAIL',
	//Update
	update: 'MEASUREMENT_UPDATE',
	updateSuccess: 'MEASUREMENT_UPDATE_SUCCESS',
	updateFail: 'MEASUREMENT_UPDATE_FAIL',
	//Delete
	delete: 'MEASUREMENT_DELETE',
	deleteSuccess: 'MEASUREMENT_DELETE_SUCCESS',
	deleteFail: 'MEASUREMENT_DELETE_FAIL',
	//Update relations
	updateRelation: 'MEASUREMENT_UPDATE_RELATION',
	updateRelationSuccess: 'MEASUREMENT_UPDATE_RELATION_SUCCESS',
	updateRelationFail: 'MEASUREMENT_UPDATE_RELATION_FAIL',
	//Delete relation
	deleteRelation: 'MEASUREMENT_DELETE_RELATION',
	deleteRelationSuccess: 'MEASUREMENT_DELETE_RELATION_SUCCESS',
	deleteRelationFail: 'MEASUREMENT_DELETE_RELATION_FAIL'
}

export const personTypes = {
	changeField: 'PERSON_CHANGE_FIELD',
	//Get All
	get: 'PERSON_GET',
	getSuccess: 'PERSON_GET_SUCCESS',
	getFail: 'PERSON_GET_FAIL',
	//Get non-linked
	getNonLinked: 'PERSON_GET_NON_LINKED',
	getNonLinkedSuccess: 'PERSON_GET_NON_LINKED_SUCCESS',
	getNonLinkedFail: 'PERSON_GET_NON_LINKED_FAIL',
	//Post
	create: 'PERSON_CREATE',
	createSuccess: 'PERSON_CREATE_SUCCESS',
	createFail: 'PERSON_CREATE_FAIL',
	//Get by id
	getById: 'PERSON_GET_BY_ID',
	getByIdSuccess: 'PERSON_GET_BY_ID_SUCCESS',
	getByIdFail: 'PERSON_GET_BY_ID_FAIL',
	//Update
	update: 'PERSON_UPDATE',
	updateSuccess: 'PERSON_UPDATE_SUCCESS',
	updateFail: 'PERSON_UPDATE_FAIL',
	//Delete
	delete: 'PERSON_DELETE',
	deleteSuccess: 'PERSON_DELETE_SUCCESS',
	deleteFail: 'PERSON_DELETE_FAIL',
	//Update relations
	updateRelation: 'PERSON_UPDATE_RELATION',
	updateRelationSuccess: 'PERSON_UPDATE_RELATION_SUCCESS',
	updateRelationFail: 'PERSON_UPDATE_RELATION_FAIL',
	//Delete relation
	deleteRelation: 'PERSON_DELETE_RELATION',
	deleteRelationSuccess: 'PERSON_DELETE_RELATION_SUCCESS',
	deleteRelationFail: 'PERSON_DELETE_RELATION_FAIL'
}

export const roleTypeTypes = {
	//Get All
	get: 'ROLETYPE_GET',
	getSuccess: 'ROLETYPE_GET_SUCCESS',
	getFail: 'ROLETYPE_GET_FAIL',
	//Get by id
	getById: 'ROLETYPE_GET_BY_ID',
	getByIdSuccess: 'ROLETYPE_GET_BY_ID_SUCCESS',
	getByIdFail: 'ROLETYPE_GET_BY_ID_FAIL',
	//Post
	create: 'ROLETYPE_CREATE',
	createSuccess: 'ROLETYPE_CREATE_SUCCESS',
	createFail: 'ROLETYPE_CREATE_FAIL',
	//Update
	update: 'ROLETYPE_UPDATE',
	updateSuccess: 'ROLETYPE_UPDATE_SUCCESS',
	updateFail: 'ROLETYPE_UPDATE_FAIL',
	//Delete
	delete: 'ROLETYPE_DELETE',
	deleteSuccess: 'ROLETYPE_DELETE_SUCCESS',
	deleteFail: 'ROLETYPE_DELETE_FAIL'
}

export const roleTypes = {
	roleAdd: 'ROLE_ADD_TO_ARRAY',
	roleDelete: 'ROLE_REMOVE_FROM_ARRAY',
	//Get All
	get: 'ROLE_GET',
	getSuccess: 'ROLE_GET_SUCCESS',
	getFail: 'ROLE_GET_FAIL',
	//Post
	create: 'ROLE_CREATE',
	createSuccess: 'ROLE_CREATE_SUCCESS',
	createFail: 'ROLE_CREATE_FAIL',
	//Get by id
	getById: 'ROLE_GET_BY_ID',
	getByIdSuccess: 'ROLE_GET_BY_ID_SUCCESS',
	getByIdFail: 'ROLE_GET_BY_ID_FAIL',
	//Update
	update: 'ROLE_UPDATE',
	updateSuccess: 'ROLE_UPDATE_SUCCESS',
	updateFail: 'ROLE_UPDATE_FAIL',
	//Delete
	delete: 'ROLE_DELETE',
	deleteSuccess: 'ROLE_DELETE_SUCCESS',
	deleteFail: 'ROLE_DELETE_FAIL',
	//Update relations
	updateRelation: 'ROLE_UPDATE_RELATION',
	updateRelationSuccess: 'ROLE_UPDATE_RELATION_SUCCESS',
	updateRelationFail: 'ROLE_UPDATE_RELATION_FAIL',
	//Delete relation
	deleteRelation: 'ROLE_DELETE_RELATION',
	deleteRelationSuccess: 'ROLE_DELETE_RELATION_SUCCESS',
	deleteRelationFail: 'ROLE_DELETE_RELATION_FAIL'
}

export const roomTypes = {
	resetState: 'ROOM_INITIAL_STATES',
	addRoom: 'ROOM_ADD',
	clearRooms: 'CREAR_ROOMS',
	//Get All
	get: 'ROOM_GET',
	getSuccess: 'ROOM_GET_SUCCESS',
	getFail: 'ROOM_GET_FAIL',
	//Post
	create: 'ROOM_CREATE',
	createSuccess: 'ROOM_CREATE_SUCCESS',
	createFail: 'ROOM_CREATE_FAIL',
	//Get by id
	getById: 'ROOM_GET_BY_ID',
	getByIdSuccess: 'ROOM_GET_BY_ID_SUCCESS',
	getByIdFail: 'ROOM_GET_BY_ID_FAIL',
	//Update
	update: 'ROOM_UPDATE',
	updateSuccess: 'ROOM_UPDATE_SUCCESS',
	updateFail: 'ROOM_UPDATE_FAIL',
	//Delete
	delete: 'ROOM_DELETE',
	deleteSuccess: 'ROOM_DELETE_SUCCESS',
	deleteFail: 'ROOM_DELETE_FAIL',
	//Update relations
	updateRelation: 'ROOM_UPDATE_RELATION',
	updateRelationSuccess: 'ROOM_UPDATE_RELATION_SUCCESS',
	updateRelationFail: 'ROOM_UPDATE_RELATION_FAIL',
	//Delete relation
	deleteRelation: 'ROOM_DELETE_RELATION',
	deleteRelationSuccess: 'ROOM_DELETE_RELATION_SUCCESS',
	deleteRelationFail: 'ROOM_DELETE_RELATION_FAIL'
}

// SubJobTypes
export const subJobTypes = {
	changeField: 'SUBJOBLIST_CHANGE_FIELD',
	// adding types to ui
	addSubJobTypesToShow: 'ADD_SUBJOB_TOSHOW',
	// remove types from ui
	removeSubJobTypeToShow: 'REMOVE_SUBJOB_TOSHOW',
	// initialize
	resetState: 'SUBJOBLIST_INITIAL_STATES',
	//Get All
	get: 'SUBJOBTYPE_GET',
	getSuccess: 'SUBJOBTYPE_GET_SUCCESS',
	getFail: 'SUBJOBTYPE_GET_FAIL',
	//Post
	create: 'SUBJOBTYPE_CREATE',
	createSuccess: 'SUBJOBTYPE_CREATE_SUCCESS',
	createFail: 'SUBJOBTYPE_CREATE_FAIL',
	//Get by id
	getById: 'SUBJOBTYPE_GET_BY_ID',
	getByIdSuccess: 'SUBJOBTYPE_GET_BY_ID_SUCCESS',
	getByIdFail: 'SUBJOBTYPE_GET_BY_ID_FAIL',
	//Update
	update: 'SUBJOBTYPE_UPDATE',
	updateSuccess: 'SUBJOBTYPE_UPDATE_SUCCESS',
	updateFail: 'SUBJOBTYPE_UPDATE_FAIL',
	//Delete
	delete: 'SUBJOBTYPE_DELETE',
	deleteSuccess: 'SUBJOBTYPE_DELETE_SUCCESS',
	deleteFail: 'SUBJOBTYPE_DELETE_FAIL',
	//Update relations
	updateRelation: 'SUBJOBTYPE_UPDATE_RELATION',
	updateRelationSuccess: 'SUBJOBTYPE_UPDATE_RELATION_SUCCESS',
	updateRelationFail: 'SUBJOBTYPE_UPDATE_RELATION_FAIL',
	//Delete relation
	deleteRelation: 'SUBJOBTYPE_DELETE_RELATION',
	deleteRelationSuccess: 'SUBJOBTYPE_DELETE_RELATION_SUCCESS',
	deleteRelationFail: 'SUBJOBTYPE_DELETE_RELATION_FAIL'
}

export const surfaceTypes = {
	//Get All
	get: 'SURFACE_GET',
	getSuccess: 'SURFACE_GET_SUCCESS',
	getFail: 'SURFACE_GET_FAIL',
	//Post
	create: 'SURFACE_CREATE',
	createSuccess: 'SURFACE_CREATE_SUCCESS',
	createFail: 'SURFACE_CREATE_FAIL',
	//Get by id
	getById: 'SURFACE_GET_BY_ID',
	getByIdSuccess: 'SURFACE_GET_BY_ID_SUCCESS',
	getByIdFail: 'SURFACE_GET_BY_ID_FAIL',
	//Update
	update: 'SURFACE_UPDATE',
	updateSuccess: 'SURFACE_UPDATE_SUCCESS',
	updateFail: 'SURFACE_UPDATE_FAIL',
	//Delete
	delete: 'SURFACE_DELETE',
	deleteSuccess: 'SURFACE_DELETE_SUCCESS',
	deleteFail: 'SURFACE_DELETE_FAIL',
	//Update relations
	updateRelation: 'SURFACE_UPDATE_RELATION',
	updateRelationSuccess: 'SURFACE_UPDATE_RELATION_SUCCESS',
	updateRelationFail: 'SURFACE_UPDATE_RELATION_FAIL',
	//Delete relation
	deleteRelation: 'SURFACE_DELETE_RELATION',
	deleteRelationSuccess: 'SURFACE_DELETE_RELATION_SUCCESS',
	deleteRelationFail: 'SURFACE_DELETE_RELATION_FAIL'
}

// User
export const userTypes = {
	changeField: 'USER_CHANGE_FIELD',
	changeFieldUsers: 'USERS_CHANGE_FIELD',
	// CREATE
	createUser: 'USER_CREATED',
	createUserSuccess: 'USER_CREATED_SUCCESS',
	createUserFail: 'USER_CREATED_FAIL',
	// CREATE ADMIN
	createAdminUser: 'CREATE_USER_ADMIN',
	createAdminUserSuccess: 'CREATE_USER_ADMIN_SUCCESS',
	createAdminUserFail: 'CREATE_USER_ADMIN_FAIL',
	// GET
	getAllUsers: 'GET_ALL_USERS',
	getAllUsersSuccess: 'GET_ALL_USERS_SUCCESS',
	getAllUsersFail: 'GET_ALL_USERS_FAIL',
	// GET BY ID
	getUser: 'GET_USER',
	getUserSuccess: 'GET_USER_SUCCESS',
	getUserFail: 'GET_USER_FAIL',
	// UPDATE
	patchUser: 'UPDATE_USER',
	patchUserSuccess: 'UPDATE_USER_SUCCESS',
	patchUserFail: 'UPDATE_USER_FAIL',
	// UPDATE REALTION
	updateUserRelation: 'UPDATE_USER_RELATION',
	updateUserRelationSuccess: 'UPDATE_USER_RELATION_SUCCESS',
	updateUserRelationFail: 'UPDATE_USER_RELATION_FAIL',
	// DELETE
	deleteUser: 'DELETE_USER',
	deleteUserSuccess: 'DELETE_USER_SUCCESS',
	deleteUserFail: 'DELETE_USER_FAIL',
	// DELETE
	restoreUser: 'RESTORE_USER',
	restoreUserSuccess: 'RESTORE_USER_SUCCESS',
	restoreUserFail: 'RESTORE_USER_FAIL',
	// DELETE REALATION
	deleteUserRelation: 'DELETE_USER_RELATION',
	deleteUserRelationSuccess: 'DELETE_USER_RELATION_SUCCESS',
	deleteUserRelationFail: 'DELETE_USER_RELATION_FAIL'
}
//WORKINADDRESS
export const workInAddressTypes = {
	updateReducerForEditing: 'WORKINADDRESS_UPDATE_REDUCER_FOR_EDITING',
	updateReducerForFinishEditing:
		'WORKINADDRESS_UPDATE_REDUCER_FOR_FINISH_EDITING',
	changeField: 'WORKINADDRESS_CHANGE_FIELD',
	changeNewApartmentName: 'WORKINADDRESS_CHANGE_NEW_APARTMENT_NAME',
	changeFieldApartment: 'WORKINADDRESS_CHANGE_FIELD_APARTMENT',
	resetStates: 'WORKINADDRESS_RESET_STATES',
	setCurrentAddress: 'WORKINADDRESS_SET_CURRENT_ADDRESS',
	//Get All
	get: 'WORKINADDRESS_GET',
	getSuccess: 'WORKINADDRESS_GET_SUCCESS',
	getFail: 'WORKINADDRESS_GET_FAIL',
	//Post
	create: 'WORKINADDRESS_CREATE',
	createSuccess: 'WORKINADDRESS_CREATE_SUCCESS',
	createFail: 'WORKINADDRESS_CREATE_FAIL',
	//Get by id
	getById: 'WORKINADDRESS_GET_BY_ID',
	getByIdSuccess: 'WORKINADDRESS_GET_BY_ID_SUCCESS',
	getByIdFail: 'WORKINADDRESS_GET_BY_ID_FAIL',
	//Get by id
	getOverviewById: 'WORKINADDRESS_GET_OVERVIEW_BY_ID',
	getOverviewByIdSuccess: 'WORKINADDRESS_GET_OVERVIEW_BY_ID_SUCCESS',
	getOverviewByIdFail: 'WORKINADDRESS_OVERVIEW_GET_BY_ID_FAIL',
	//Update
	update: 'WORKINADDRESS_UPDATE',
	updateSuccess: 'WORKINADDRESS_UPDATE_SUCCESS',
	updateFail: 'WORKINADDRESS_UPDATE_FAIL',
	//Delete
	delete: 'WORKINADDRESS_DELETE',
	deleteSuccess: 'WORKINADDRESS_DELETE_SUCCESS',
	deleteFail: 'WORKINADDRESS_DELETE_FAIL',
	//Update relations
	updateRelation: 'WORKINADDRESS_UPDATE_RELATION',
	updateRelationSuccess: 'WORKINADDRESS_UPDATE_RELATION_SUCCESS',
	updateRelationFail: 'WORKINADDRESS_UPDATE_RELATION_FAIL',
	//Delete relation
	deleteRelation: 'WORKINADDRESS_DELETE_RELATION',
	deleteRelationSuccess: 'WORKINADDRESS_DELETE_RELATION_SUCCESS',
	deleteRelationFail: 'WORKINADDRESS_DELETE_RELATION_FAIL',
	//Update company link
	updateCompanyLink: 'WORKINADDRESS_UPDATE_COMPANY_LINK',
	updateCompanyLinkSuccess: 'WORKINADDRESS_UPDATE_COMPANY_LINK_SUCCESS',
	updateCompanyLinkFail: 'WORKINADDRESS_UPDATE_COMPANY_LINK_FAIL',
	//Delete company link
	deleteCompanyLink: 'WORKINADDRESS_DELETE_COMPANY_LINK',
	deleteCompanyLinkSuccess: 'WORKINADDRESS_DELETE_COMPANY_LINK_SUCCESS',
	deleteCompanyLinkFail: 'WORKINADDRESS_DELETE_COMPANY_LINK_FAIL',
	// Update status
	updateStatus: 'WORKINADDRESS_UPDATE_STATUS',
	updateStatusSuccess: 'WORKINADDRESS_UPDATE_STATUS_SUCCESS',
	updateStatusFail: 'WORKINADDRESS_UPDATE_STATUS_FAIL'
}

// WorkEvent Types
export const workEventTypes = {
	setWorkEvent: 'SET_WORK_EVENT',
	changeField: 'WORKEVENT_CHANGE_FIELD',
	changeFieldAttributeValue: 'ATTRIBUTE_VALUE_CHANGE_FIELD',
	// adding values
	addSubJobType: 'ADD_SUBJOB_TYPE',
	// removing values
	removeSubJob: 'REMOVE_SUBJOB',
	// initialize
	resetState: 'WORKEVENT_INITIAL_STATES',
	//Post
	create: 'WORK_EVENT_CREATE',
	createSuccess: 'WORK_EVENT_CREATE_SUCCESS',
	createFail: 'WORK_EVENT_CREATE_FAIL',
	save: 'WORK_EVENT_SAVE',
	saveSuccess: 'WORK_EVENT_SAVE_SUCCESS',
	saveFail: 'WORK_EVENT_SAVE_FAIL',
	createWithRelations: 'WORK_EVENT_CREATE_WITH_RELATIONS',
	createWithRelationsSuccess: 'WORK_EVENT_CREATE_WITH_RELATIONS_SUCCESS',
	createWithRelationsFail: 'WORK_EVENT_CREATE_WITH_RELATIONS_FAIL',

	//Get by id
	getById: 'WORK_EVENT_GET_BY_ID',
	getByIdSuccess: 'WORK_EVENT_GET_BY_ID_SUCCESS',
	getByIdFail: 'WORK_EVENT_GET_BY_ID_FAIL',
	//Update
	update: 'WORK_EVENT_UPDATE',
	updateSuccess: 'WORK_EVENT_UPDATE_SUCCESS',
	updateFail: 'WORK_EVENT_UPDATE_FAIL',
	//Delete
	delete: 'WORK_EVENT_DELETE',
	deleteSuccess: 'WORK_EVENT_DELETE_SUCCESS',
	deleteFail: 'WORK_EVENT_DELETE_FAIL',
	//Update relations
	updateRelation: 'WORK_EVENT_UPDATE_RELATION',
	updateRelationSuccess: 'WORK_EVENT_UPDATE_RELATION_SUCCESS',
	updateRelationFail: 'WORK_EVENT_UPDATE_RELATION_FAIL',
	//Delete relation
	deleteRelation: 'WORK_EVENT_DELETE_RELATION',
	deleteRelationSuccess: 'WORK_EVENT_DELETE_RELATION_SUCCESS',
	deleteRelationFail: 'WORK_EVENT_DELETE_RELATION_FAIL'
}

// WorkEvent Types
export const workingAttributeTypes = {
	changeField: 'WORKING_ATTRIBUTE_CHANGE_FIELD',
	// initialize
	resetState: 'WORKING_ATTRIBUTE_INITIAL_STATES',
	//Get All
	getAll: 'WORKING_ATTRIBUTE_GET_ALL',
	getEditable: 'WORKING_ATTRIBUTE_GET_EDITABLE',
	getAllSuccess: 'WORKING_ATTRIBUTE_GET_ALL_SUCCESS',
	getAllFail: 'WORKING_ATTRIBUTE_GET_ALL_FAIL',
	//Post
	create: 'WORKING_ATTRIBUTE_CREATE',
	createSuccess: 'WORKING_ATTRIBUTE_CREATE_SUCCESS',
	createFail: 'WORKING_ATTRIBUTE_CREATE_FAIL',
	//Get by id
	getById: 'WORKING_ATTRIBUTE_GET_BY_ID',
	getByIdSuccess: 'WORKING_ATTRIBUTE_GET_BY_ID_SUCCESS',
	getByIdFail: 'WORKING_ATTRIBUTE_GET_BY_ID_FAIL',
	//Update
	update: 'WORKING_ATTRIBUTE_UPDATE',
	updateSuccess: 'WORKING_ATTRIBUTE_UPDATE_SUCCESS',
	updateFail: 'WORKING_ATTRIBUTE_UPDATE_FAIL',
	//Delete
	delete: 'WORKING_ATTRIBUTE_DELETE',
	deleteSuccess: 'WORKING_ATTRIBUTE_DELETE_SUCCESS',
	deleteFail: 'WORKING_ATTRIBUTE_DELETE_FAIL',
	//Update relations
	updateRelation: 'WORKING_ATTRIBUTE_UPDATE_RELATION',
	updateRelationSuccess: 'WORKING_ATTRIBUTE_UPDATE_RELATION_SUCCESS',
	updateRelationFail: 'WORKING_ATTRIBUTE_UPDATE_RELATION_FAIL',
	//Delete relation
	deleteRelation: 'WORKING_ATTRIBUTE_DELETE_RELATION',
	deleteRelationSuccess: 'WORKING_ATTRIBUTE_DELETE_RELATION_SUCCESS',
	deleteRelationFail: 'WORKING_ATTRIBUTE_DELETE_RELATION_FAIL',
	// Toggle subjobtype link
	toggleLink: 'WORKING_ATTRIBUTE_TOGGLE_LINK',
	toggleLinkSuccess: 'WORKING_ATTRIBUTE_TOGGLE_LINK_SUCCESS',
	toggleLinkFail: 'WORKING_ATTRIBUTE_TOGGLE_LINK_FAIL'
}

// WorkEvent Types
export const workingAttributeValueTypes = {
	changeField: 'WORKING_ATTRIBUTE_VALUE_CHANGE_FIELD',
	// initialize
	resetState: 'WORKING_ATTRIBUTE_VALUE_INITIAL_STATES',
	//Get All
	getAll: 'WORKING_ATTRIBUTE_VALUE_GET_ALL',
	getAllSuccess: 'WORKING_ATTRIBUTE_VALUE_GET_ALL_SUCCESS',
	getAllFail: 'WORKING_ATTRIBUTE_VALUE_GET_ALL_FAIL',
	//Post
	create: 'WORKING_ATTRIBUTE_VALUE_CREATE',
	createSuccess: 'WORKING_ATTRIBUTE_VALUE_CREATE_SUCCESS',
	createFail: 'WORKING_ATTRIBUTE_VALUE_CREATE_FAIL',
	//Get by id
	getById: 'WORKING_ATTRIBUTE_VALUE_GET_BY_ID',
	getByIdSuccess: 'WORKING_ATTRIBUTE_VALUE_GET_BY_ID_SUCCESS',
	getByIdFail: 'WORKING_ATTRIBUTE_VALUE_GET_BY_ID_FAIL',
	//Update
	update: 'WORKING_ATTRIBUTE_VALUE_UPDATE',
	updateSuccess: 'WORKING_ATTRIBUTE_VALUE_UPDATE_SUCCESS',
	updateFail: 'WORKING_ATTRIBUTE_VALUE_UPDATE_FAIL',
	//Delete
	delete: 'WORKING_ATTRIBUTE_VALUE_DELETE',
	deleteSuccess: 'WORKING_ATTRIBUTE_VALUE_DELETE_SUCCESS',
	deleteFail: 'WORKING_ATTRIBUTE_VALUE_DELETE_FAIL',
	//Update relations
	updateRelation: 'WORKING_ATTRIBUTE_VALUE_UPDATE_RELATION',
	updateRelationSuccess: 'WORKING_ATTRIBUTE_VALUE_UPDATE_RELATION_SUCCESS',
	updateRelationFail: 'WORKING_ATTRIBUTE_VALUE_UPDATE_RELATION_FAIL',
	//Delete relation
	deleteRelation: 'WORKING_ATTRIBUTE_VALUE_DELETE_RELATION',
	deleteRelationSuccess: 'WORKING_ATTRIBUTE_VALUE_DELETE_RELATION_SUCCESS',
	deleteRelationFail: 'WORKING_ATTRIBUTE_VALUE_DELETE_RELATION_FAIL'
}

// Report types
export const reportTypes = {
	inspectionReport: 'INSPECTION_REPORT',
	inspectionReportSuccess: 'INSPECTION_REPORT_SUCCESS',
	inspectionReportFailure: 'INSPECTION_REPORT_FAILURE',

	measurementReport: 'MEASUREMENT_REPORT',
	measurementReportSuccess: 'MEASUREMENT_REPORT_SUCCESS',
	measurementReportFailure: 'MEASUREMENT_REPORT_FAILURE',

	jobSpecificationReport: 'JOB_SPECIFICATION_REPORT',
	jobSpecificationReportSuccess: 'JOB_SPECIFICATION_REPORT_SUCCESS',
	jobSpecificationReportFailure: 'JOB_SPECIFICATION_REPORT_FAILURE',

	jobSpecificationReportOverview: 'JOB_SPECIFICATION_REPORT_OVERVIEW',
	jobSpecificationReportOverviewSuccess:
		'JOB_SPECIFICATION_REPORT_OVERVIEW_SUCCESS',
	jobSpecificationReportOverviewFailure:
		'JOB_SPECIFICATION_REPORT_OVERVIEW_FAILURE',

	jobSpecificationReportDetails: 'JOB_SPECIFICATION_REPORT_DETAILS',
	jobSpecificationReportDetailsSuccess:
		'JOB_SPECIFICATION_REPORT_DETAILS_SUCCESS',
	jobSpecificationReportDetailsFailure:
		'JOB_SPECIFICATION_REPORT_DETAILS_FAILURE'
}

export const parameterTypes = {
	getAll: 'PARAMETER_GET_ALL',
	update: 'PARAMETER_UPDATE'
}

export const tooltipTypes = {
	getAll: 'TOOLTIP_GET_ALL',
	update: 'TOOLTIP_UPDATE'
}

export const piecesSubJobTypes = {
	getAll: 'PIECESSUBJOB_GET_ALL',
	update: 'PIECESSUBJOB_UPDATE',
	create: 'PIECESSUBJOB_CREATE'
}

export const parkingFeeTypes = {
	getAll: 'PARKINGFEE_GET_ALL',
	update: 'PARKINGFEE_UPDATE',
	create: 'PARKINGFEE_CREATE'
}
